import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import { useNavigate } from "react-router-dom";

function HistoryPromo() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [payment, setPayment] = useState(null);
    const [profile, setProfile] = useState(null);
    //const [isShow, setShow] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                setPayment(res.data.data);
            })
            .catch((err) => function () {});
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch((err) => sessionStorage.removeItem("user"));
    }, []);
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Lịch Sử Nhận Thưởng</h1>
            </div>
            <div className="main">
                {payment ? (
                    <AnimatedPage>
                        <div className="content-history">
                            {payment?.map((item, key) => (
                                <>
                                    {item.type_payment === "KHUYẾN MÃI" ? (
                                        <>
                                            <div className="item_inner">
                                                <div className="item_history">
                                                    <div className="title_item_history">
                                                        <span className="sanh"> {item.type_payment}</span>
                                                        <span
                                                            className={`type_state ${
                                                                item.status_payment === "Pending" ? "pending" : item.status_payment === "Success" ? "win" : "lose"
                                                            }`}>
                                                            {item.status_payment}
                                                        </span>
                                                    </div>
                                                    {item?.detail?.length > 1 && <div className="id_history_sanh">Nội dung : {item?.detail}</div>}
                                                    {item?.note?.length > 1 && <div className="id_history_sanh">Lý do : {item?.note}</div>}
                                                </div>
                                                <div className="money_history">
                                                    <span className="money">
                                                        {item.status_payment === "Pending" ? "" : item.status_payment === "Success" ? "+" : ""}
                                                        {Number(item.km).toLocaleString("vi-VN")}
                                                    </span>
                                                    <div className="time_choose">{formatDate(new Date(item.createdAt))}</div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {item.type_payment === "THƯỞNG" && (
                                                <>
                                                    <div className="item_inner">
                                                        <div className="item_history">
                                                            <div className="title_item_history">
                                                                <span className="sanh"> {item.type_payment}</span>
                                                                <span
                                                                    className={`type_state ${
                                                                        item.status_payment === "Pending" ? "pending" : item.status_payment === "Success" ? "win" : "lose"
                                                                    }`}>
                                                                    {item.status_payment}
                                                                </span>
                                                            </div>
                                                            {item?.detail?.length > 1 && <div className="id_history_sanh">Nội dung : {item?.detail}</div>}
                                                            {item?.note?.length > 1 && <div className="id_history_sanh">Lý do : {item?.note}</div>}
                                                        </div>
                                                        <div className="money_history">
                                                            <span className="money">
                                                                {item.status_payment === "Pending" ? "" : item.status_payment === "Success" ? "+" : ""}
                                                                {Number(item.money).toLocaleString("vi-VN")}
                                                            </span>
                                                            <div className="time_choose">{formatDate(new Date(item.createdAt))}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                        </div>
                    </AnimatedPage>
                ) : (
                    <div className="no_record">Chưa có dữ liệu</div>
                )}

                <Footer profile={profile} />
            </div>
        </>
    );
}
export default HistoryPromo;
