import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import axios from "axios";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";

function DetailProfile() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    //const [isShow, setShow] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => sessionStorage.removeItem("user"));
    }, []);
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Chi tiết tài khoản</h1>
            </div>
            <div className="main">
                <AnimatedPage>
                    <h2 className="title-detail-profile">Tài khoản</h2>
                    <div className="menu-recharge">
                        <div className="box-detail-profile">
                            <h3>Số dư hiện tại</h3>
                            <p>{profile?.money?.toLocaleString("vi-VN")}</p>
                        </div>
                        <div className="box-detail-profile">
                            <h3>Tổng nạp thành công</h3>
                            <p>{profile?.tongnap?.toLocaleString("vi-VN")}</p>
                        </div>
                        <div className="box-detail-profile">
                            <h3>Tổng khuyến mãi</h3>
                            <p>{profile?.khuyenmai?.toLocaleString("vi-VN")}</p>
                        </div>
                        <div className="box-detail-profile">
                            <h3>Tổng rút thành công</h3>
                            <p>{profile?.tongrut?.toLocaleString("vi-VN")}</p>
                        </div>
                    </div>
                    {/*<h2 className="title-detail-profile">Xổ số</h2>
                    <div className="menu-recharge">
                        <div className="box-detail-profile">
                            <h3>Tổng cược xổ số</h3>
                            <p>{profile?.totalbet?.toLocaleString("vi-VN")}</p>
                        </div>
                        <div className="box-detail-profile">
                            <h3>Cược xổ số đang chờ</h3>
                            <p>{profile?.processing?.toLocaleString("vi-VN")}</p>
                        </div>
                        <div className="box-detail-profile">
                            <h3>Cược xổ số thành công</h3>
                            <p>{profile?.success?.toLocaleString("vi-VN")}</p>
                        </div>
                        <div className="box-detail-profile">
                            <h3>Tiền thắng xổ số</h3>
                            <p>{profile?.totalwin?.toLocaleString("vi-VN")}</p>
                        </div>
                    </div>
                    <h2 className="title-detail-profile">Sảnh game</h2>
                    <div className="menu-recharge">
                        <div className="box-detail-profile">
                            <h3>Tổng cược sảnh</h3>
                            <p>{profile?.totalbetapi?.toLocaleString("vi-VN")}</p>
                        </div>
                        <div className="box-detail-profile">
                            <h3>Tiền thắng sảnh</h3>
                            <p>{profile?.totalwinapi?.toLocaleString("vi-VN")}</p>
                        </div>
    </div>*/}
                </AnimatedPage>
            </div>
            <Footer />
        </>
    );
}

export default DetailProfile;
