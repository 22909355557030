import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function ResetPassword() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => sessionStorage.removeItem("user"));
    }, []);
    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
        if (data.password != data.checkpassword) {
            swal("Thông báo", "Xác nhận mật khẩu mới không đúng", "error");
            return false;
        }
        const formData = {
            password: data.oldpassword,
            newpassword: data.password
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/password`, formData)
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đổi mật khẩu thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    navigate("/profile");
                });
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Mật khẩu đăng nhập</h1>
            </div>
            <div className="main">
                <AnimatedPage>
                    <div className="content_profile" style={{ marginTop: "0.3rem" }}>
                        <form className="form-lg form-box" onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <div className="input">
                                    <input
                                        className="ipadd"
                                        type="password"
                                        {...register("oldpassword", {
                                            required: true
                                        })}
                                        placeholder="Mật khẩu cũ"
                                    />
                                </div>
                                <div className="input">
                                    <input
                                        className="ipadd"
                                        type="password"
                                        {...register("password", {
                                            required: true
                                        })}
                                        placeholder="Mật khẩu mới"
                                    />
                                </div>
                                <div className="input">
                                    <input
                                        className="ipadd"
                                        type="password"
                                        {...register("checkpassword", {
                                            required: true
                                        })}
                                        placeholder="Nhập lại mật khẩu"
                                    />
                                </div>
                                <button type="submit" className="btn-submit">
                                    Xác nhận
                                </button>
                            </div>
                        </form>
                    </div>
                </AnimatedPage>
            </div>
            <Footer />
        </>
    );
}
export default ResetPassword;
