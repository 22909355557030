import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import { useNavigate } from "react-router-dom";

function TopGame() {
    const [profile, setProfile] = useState(null);
    const [bxh1, setBXH1] = useState(null);
    const [bxh21, setBXH21] = useState(null);
    const [bxh22, setBXH22] = useState(null);
    const [bxh31, setBXH31] = useState(null);
    const [bxh32, setBXH32] = useState(null);
    const [bxh4, setBXH4] = useState(null);
    const [profit, setProfit] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch((err) => sessionStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/profit/get`, {}).then((res) => {
            setProfit(res.data.data[0]);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/greedy/bxh`, {}).then((res) => {
            setBXH1(res.data.data);
        });
        {/*axios.get(`${process.env.REACT_APP_API_URL}/Xoso3/bxh`, {}).then((res) => {
            setBXH21(res.data.data);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/Xoso5/bxh`, {}).then((res) => {
            setBXH22(res.data.data);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/taixiu1/bxh`, {}).then((res) => {
            setBXH31(res.data.data);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/taixiu3/bxh`, {}).then((res) => {
            setBXH32(res.data.data);
        });*/}
        axios.get(`${process.env.REACT_APP_API_URL}/catdog/bxh`, {}).then((res) => {
            setBXH4(res.data.data);
        });
    }, []);
    const [activeTab, setActiveTab] = useState("tab1");
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const [activeTab2, setActiveTab2] = useState("tab21");
    const handleTabClick2 = (tabName) => {
        setActiveTab2(tabName);
    };
    const [activeTab3, setActiveTab3] = useState("tab31");
    const handleTabClick3 = (tabName) => {
        setActiveTab3(tabName);
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Thưởng TOP hàng ngày</h1>
            </div>
            <div className="main">
                <AnimatedPage>
                    <div className="text_choose_center" style={{ padding: "0", position: "relative" }}>
                        <ul className="tab-navigation" style={{ margin: "0", padding: "0.32rem 0" }}>
                            <li className={activeTab === "tab1" ? "active" : ""} onClick={() => handleTabClick("tab1")} style={{ width: "24%", flex: "none" }}>
                                Greedy
                            </li>
                            <li className={activeTab === "tab4" ? "active" : ""} onClick={() => handleTabClick("tab4")} style={{ width: "24%", flex: "none" }}>
                                Cat Dog
                            </li>
                            {/*<li className={activeTab === "tab2" ? "active" : ""} onClick={() => handleTabClick("tab2")} style={{ width: "24%", flex: "none" }}>
                                Xổ số nhanh
                            </li>
                            <li className={activeTab === "tab3" ? "active" : ""} onClick={() => handleTabClick("tab3")} style={{ width: "24%", flex: "none" }}>
                                Tài xỉu
                            </li>*/}
                        </ul>
                        <div className="thuongtop">
                            {activeTab === "tab1" && (
                                <>
                                    {bxh1 ? (
                                        bxh1.map((item, index) => (
                                            <div className="item">
                                                <div className="name">
                                                    {item?.username}
                                                    <ins>{item?.success?.toLocaleString("vi-VN")}</ins>
                                                </div>
                                                <div className="prize">
                                                    {profit && profit["top" + (index + 1)] > 0 && (
                                                        <>
                                                            Thưởng <br /> <span>{profit["top" + (index + 1)]?.toLocaleString("vi-VN")}</span>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="rank">
                                                    <i className={`icon-rank icon-rank_` + (index + 1)}>{index + 1}</i>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no_record">Chưa có dữ liệu</div>
                                    )}
                                </>
                            )}
                            {activeTab === "tab4" && (
                                <>
                                    {bxh4 ? (
                                        bxh4.map((item, index) => (
                                            <div className="item">
                                                <div className="name">
                                                    {item?.username}
                                                    <ins>{item?.success?.toLocaleString("vi-VN")}</ins>
                                                </div>
                                                <div className="prize">
                                                    {profit && profit["top" + (index + 1)] > 0 && (
                                                        <>
                                                            Thưởng <br /> <span>{profit["top" + (index + 1)]?.toLocaleString("vi-VN")}</span>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="rank">
                                                    <i className={`icon-rank icon-rank_` + (index + 1)}>{index + 1}</i>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no_record">Chưa có dữ liệu</div>
                                    )}
                                </>
                            )}
                            {activeTab === "tab2" && (
                                <>
                                    <ul className="sub_tab">
                                        <li className={activeTab2 === "tab21" ? "active" : ""} onClick={() => handleTabClick2("tab21")}>
                                            3 phút
                                        </li>
                                        <li className={activeTab2 === "tab22" ? "active" : ""} onClick={() => handleTabClick2("tab22")}>
                                            5 phút
                                        </li>
                                    </ul>
                                    {activeTab2 === "tab21" && (
                                        <>
                                            {bxh21 ? (
                                                bxh21.map((item, index) => (
                                                    <div className="item">
                                                        <div className="name">
                                                            {item?.username}
                                                            <ins>{item?.success?.toLocaleString("vi-VN")}</ins>
                                                        </div>
                                                        <div className="prize">
                                                            {profit && profit["top" + (index + 1)] > 0 && (
                                                                <>
                                                                    Thưởng <br /> <span>{profit["top" + (index + 1)]?.toLocaleString("vi-VN")}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="rank">
                                                            <i className={`icon-rank icon-rank_` + (index + 1)}>{index + 1}</i>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no_record">Chưa có dữ liệu</div>
                                            )}
                                        </>
                                    )}
                                    {activeTab2 === "tab22" && (
                                        <>
                                            {bxh22 ? (
                                                bxh22.map((item, index) => (
                                                    <div className="item">
                                                        <div className="name">
                                                            {item?.username}
                                                            <ins>{item?.success?.toLocaleString("vi-VN")}</ins>
                                                        </div>
                                                        <div className="prize">
                                                            {profit && profit["top" + (index + 1)] > 0 && (
                                                                <>
                                                                    Thưởng <br /> <span>{profit["top" + (index + 1)]?.toLocaleString("vi-VN")}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="rank">
                                                            <i className={`icon-rank icon-rank_` + (index + 1)}>{index + 1}</i>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no_record">Chưa có dữ liệu</div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {activeTab === "tab3" && (
                                <>
                                    <ul className="sub_tab">
                                        <li className={activeTab3 === "tab31" ? "active" : ""} onClick={() => handleTabClick3("tab31")}>
                                            1 phút
                                        </li>
                                        <li className={activeTab3 === "tab32" ? "active" : ""} onClick={() => handleTabClick3("tab32")}>
                                            3 phút
                                        </li>
                                    </ul>
                                    {activeTab3 === "tab31" && (
                                        <>
                                            {bxh31 ? (
                                                bxh31.map((item, index) => (
                                                    <div className="item">
                                                        <div className="name">
                                                            {item?.username}
                                                            <ins>{item?.success?.toLocaleString("vi-VN")}</ins>
                                                        </div>
                                                        <div className="prize">
                                                            {profit && profit["top" + (index + 1)] > 0 && (
                                                                <>
                                                                    Thưởng <br /> <span>{profit["top" + (index + 1)]?.toLocaleString("vi-VN")}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="rank">
                                                            <i className={`icon-rank icon-rank_` + (index + 1)}>{index + 1}</i>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no_record">Chưa có dữ liệu</div>
                                            )}
                                        </>
                                    )}
                                    {activeTab3 === "tab32" && (
                                        <>
                                            {bxh32 ? (
                                                bxh32.map((item, index) => (
                                                    <div className="item">
                                                        <div className="name">
                                                            {item?.username}
                                                            <ins>{item?.success?.toLocaleString("vi-VN")}</ins>
                                                        </div>
                                                        <div className="prize">
                                                            {profit && profit["top" + (index + 1)] > 0 && (
                                                                <>
                                                                    Thưởng <br /> <span>{profit["top" + (index + 1)]?.toLocaleString("vi-VN")}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="rank">
                                                            <i className={`icon-rank icon-rank_` + (index + 1)}>{index + 1}</i>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no_record">Chưa có dữ liệu</div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </AnimatedPage>
            </div>
            <Footer />
        </>
    );
}

export default TopGame;
