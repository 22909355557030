import Footer from "../components/Footer";
import "../user/profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//import MoveUpIcon from "@mui/icons-material/MoveUp";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
//import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
//import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AnimatedPage from "../components/AnimatedPage";

function Profile() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => {
                sessionStorage.removeItem("user");
                navigate("/login");
            });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);
    return (
        <>
            <div className="main" style={{ padding: "0", background: "#f1f1f1" }}>
                {profile && (
                    <AnimatedPage>
                        <div className="main-profile">
                            <div className="info-block">
                                <div className="info-block-top">
                                    <div>
                                        <img
                                            alt=""
                                            src={require("../../img/profile-picture.jpg")}
                                            style={{ height: "70px", width: "70px", borderRadius: "100%", boxShadow: "var(--cg-avatar-shadow-small)" }}
                                        />
                                    </div>
                                    <div>
                                        {profile?.username ? (
                                            <div>
                                                {profile?.username}
                                                <br />
                                                <img alt="" src={`/statics/i_vip${profile?.level}.png.webp`} style={{ height: "28px" }} />
                                            </div>
                                        ) : (
                                            "Vui lòng đăng nhập/đăng ký"
                                        )}
                                    </div>
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        sessionStorage.removeItem("user");
                                        localStorage.removeItem("currentUser");
                                        localStorage.removeItem("profile");
                                        localStorage.removeItem("data");
                                        localStorage.removeItem("data1");
                                        var now = new Date();
                                        var time = now.getTime();
                                        var expireTime = time;
                                        now.setTime(expireTime);
                                        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                                        navigate("/login");
                                    }}>
                                    <Link
                                        to="/"
                                        style={{
                                            textDecoration: "none",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            justifyContent: "center",
                                            color: "#4d595e",
                                            fontSize: "0.4rem"
                                        }}>
                                        <img alt="" src={require("../../static/i_quit.png")} style={{ height: "24px" }} />
                                        Đăng Xuất
                                    </Link>
                                </div>
                            </div>
                            <div className="info-block-center">
                                <div className="info-block-vip">
                                    <div class="wallet-amount" style={{ display: "flex", justifyContent: "space-between", padding: "15px" }}>
                                        {profile ? (
                                            <div style={{ display: "flex", alignItems: "center", fontSize: "15px", fontWeight: "bold", gap: "5px" }}>VÍ TIỀN CHÍNH</div>
                                        ) : (
                                            <button className="btn-login" onClick={() => navigate("/login")}>
                                                <img src="/statics/i_home_login.png.webp" />
                                                <span>Đăng nhập</span>
                                            </button>
                                        )}
                                        {profile ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    gap: "5px",
                                                    color: "#f09b1b",
                                                    width: "150px"
                                                }}>
                                                <img src="/statics/i_amount.png.webp" width="28" />
                                                <span>{Math.floor(profile?.money).toLocaleString("vi-VN")}</span>
                                            </div>
                                        ) : (
                                            <button className="btn-register" onClick={() => navigate("/register")}>
                                                <img src="/statics/i_home_register.png.webp" />
                                                <span>Đăng ký</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="dash-divider"></div>
                                <div class="wallet-func">
                                    <div class="wallet-func-item">
                                        <Link to="/transfergame">
                                            <img src="/statics/i_h_change.png.webp" />
                                            <h3>Chuyển Khoản</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/recharge">
                                            <img src="/statics/i_h_wallet.png.webp" />
                                            <h3>Nạp Tiền</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/withdraw">
                                            <img src="/statics/i_h_withdraw.png.webp" />
                                            <h3>Rút Tiền</h3>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="info-block-center">
                                <div className="info-block-title">
                                    <img alt="" src="/statics/i_favor.png.webp" style={{ height: "28px" }} />
                                    <span>CHỨC NĂNG THÔNG DỤNG</span>
                                </div>
                                <div class="wallet-func">
                                    <div class="wallet-func-item">
                                        <Link to="/recharge_record">
                                            <img src="/statics/i_wallet.png.webp" />
                                            <h3>Lịch sử nạp tiền</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/withdraw_record">
                                            <img src="/statics/i_lock_wallet.png.webp" />
                                            <h3>Lịch sử rút tiền</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/historyplay">
                                            <img src="/statics/i_recordbet.png.webp" />
                                            <h3>Lịch sử đặt cược</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/historycasino">
                                            <img src="/statics/i_transrecord.png.webp" />
                                            <h3>Lịch sử cược sảnh</h3>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="info-block-center">
                                <div className="info-block-title">
                                    <img alt="" src="/statics/i_setting.png.webp" style={{ height: "28px" }} />
                                    <span>CHỨC NĂNG KHÁC</span>
                                </div>
                                <div class="wallet-func2">
                                    <div class="wallet-func-item">
                                        <Link to="/addbank">
                                            <img src="/statics/i_agentrecord.png.webp" />
                                            <h3>Liên kết ngân hàng</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/invite">
                                            <img src="/statics/i_link.png.webp" />
                                            <h3>Mời bạn bè</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/password">
                                            <img src="/statics/i_transaction.png.webp" />
                                            <h3>Mật khẩu đăng nhập</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/pass2">
                                            <img src="/statics/i_transaction.png.webp" />
                                            <h3>Mật khẩu rút tiền</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/history">
                                            <img src="/statics/i_gamerecord.png.webp" />
                                            <h3>Lịch sử chuyển tiền</h3>
                                        </Link>
                                    </div>
                                    <div class="wallet-func-item">
                                        <Link to="/promo">
                                            <img src="/statics/i_member.png.webp" />
                                            <h3>Mã khuyến mãi</h3>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="info-block-center" style={{ marginBottom: "90px" }}>
                                <div className="account__menu">
                                    <Link to="/notification" className="account__menu-item">
                                        <span>
                                            <img alt="" src="/statics/i_rebate.png.webp" style={{ height: "28px" }} />
                                            Khuyến mãi
                                        </span>
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Link>
                                    <a href={setting?.down_link} className="account__menu-item">
                                        <span>
                                            <img alt="" src="/statics/i_downlaod.png.webp" style={{ height: "28px" }} />
                                            Tải App
                                        </span>
                                        <KeyboardArrowRightOutlinedIcon />
                                    </a>
                                    <Link to="/service" className="account__menu-item">
                                        <span>
                                            <img alt="" src="/statics/i_qna.png.webp" style={{ height: "28px" }} />
                                            Chăm sóc khách hàng
                                        </span>
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </AnimatedPage>
                )}
            </div>
            <Footer profile={profile} />
        </>
    );
}
export default Profile;
