import axios from "axios";
import { useEffect, useState, useRef } from "react";
import swal from "sweetalert";
//import Footer from "../../../components/Footer/Footer";
import { GetNameChoose } from "../../../funcUtils";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { Svga } from "react-svga";
import cat from "./video/cat.svga";
import dog from "./video/dog.svga";
import draw from "./video/draw.svga";
import waiting from "./video/waiting.svga";
import time from "./video/countdown.svga";
import battle from "./video/battle-ing.svga";
import "./CatDog.css";
import useWebSocket, { ReadyState } from "react-use-websocket";

function CatDog() {
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(process.env.REACT_APP_API_SOCKET);
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [historyGame, setHistoryGame] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [KQ, setKQ] = useState(null);
    const [WIN, setWIN] = useState(null);
    const [LOSE, setLOSE] = useState(null);
    const [total, setTotal] = useState(null);
    const [setting, setSetting] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [newMoney, setNewMoney] = useState(0);
    const [active, setActive] = useState(null);
    const [counters, setCounters] = useState({ s1: 0, s2: 0, s3: 0, s4: 0, s5: 0 });
    const [counters2, setCounters2] = useState([]);
    const [showConfetti, setShowConfetti] = useState(false);
    const [bxh, setBXH] = useState(null);
    const [viewBXH, setViewBXH] = useState(false);
    const [current, setCurrent] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [activeOption, setActiveOption] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    function formatDate2(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2)
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const getProfile = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
        });
    };
    function getHistoryBet() {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "CatDog" } })
            .then((res) => {
                setHistoryGame(res.data.data);
                setCounters2(res.data.data.filter((item) => item.lot === bet?.id_bet && formatDate(new Date(item?.createdAt)) == formatDate(new Date(bet?.createdAt))));
            })
            .catch((err) => function () {});
    }
    useEffect(() => {
        if (firstLoad) {
            getProfile();
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
            axios.get(`${process.env.REACT_APP_API_URL}/catdog/get`).then((res) => {
                setBet(res.data.data);
                setDulieunhap(new Date(res.data.data.createdAt));
                setStart(true);
            });
            axios
                .get(`${process.env.REACT_APP_API_URL}/catdog/getallbet`, {})
                .then((res) => {
                    setTotal(res.data.data);
                    if (res.data?.data[0]?.result == "4" || res.data?.data[0]?.result == "5") {
                        setShowConfetti(true);
                    }
                })
                .catch(() => setTotal(null));
            /*axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotifi`, {}).then((res) => {
                setVisible({
                    money: res.data.data[0]?.money.toLocaleString("vi-VN"),
                    id: res.data.data[0]._id
                });
            });*/
            axios.get(`${process.env.REACT_APP_API_URL}/catdog/bxh`, {}).then((res) => {
                setBXH(res.data.data);
            });
            getHistoryBet();
            setFirstLoad(false);
        }
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            if (Math.floor(60 - (new Date() - dulieunhap) / 1000) < 0) {
                getProfile();
                axios.get(`${process.env.REACT_APP_API_URL}/catdog/get`).then((res) => {
                    setBet(res.data.data);
                    setDulieunhap(new Date(res.data.data.createdAt));
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/catdog/getallbet`, {})
                    .then((res) => {
                        setTotal(res.data.data);
                        if (res.data?.data[0]?.result == "4" || res.data?.data[0]?.result == "5") {
                            setShowConfetti(true);
                        }
                    })
                    .catch(() => setTotal(null));
                axios.get(`${process.env.REACT_APP_API_URL}/catdog/bxh`, {}).then((res) => {
                    setBXH(res.data.data);
                });
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const { type, data, user } = lastJsonMessage;
            if (type === "choose_catdog_fail" && user == profile?.username) {
                swal("Thất bại", data.message, "error");
            }
            if (type === "choose_catdog_success" && user == profile?.username) {
                getProfile();
                setTimeout(() => {
                    //setIsSubmitting(false);
                }, 1500);
            }
        }
    }, [lastJsonMessage]);
    useEffect(() => {
        const heartBeat = () => {
            if (lastJsonMessage !== null) {
                const { type, data, user } = lastJsonMessage;
                if (type == "heartbeat") {
                    sendJsonMessage({ type: "heartbeat", data: "heartbeat" });
                }
            }
            sendJsonMessage({ type: "heartbeat", data: "heartbeat" });
        };

        /*const timer = setInterval(() => {
            heartBeat();
            axios
                .get(`${process.env.REACT_APP_API_URL}/catdog/getcurrent`)
                .then((res) => {
                    setCurrent(res.data.data);
                })
                .catch(() => setCurrent(null));
        }, 1000);

        return () => clearInterval(timer);*/
    }, []);
    function showKQ() {
        /*axios.get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "CatDog", limit: "10" } }).then((res) => {
            if (res.data?.data.length == 0) {
                setWIN(null);
                setLOSE(null);
                return;
            }
            if (Number(res.data?.data[0]?.id_bet?.id_bet) === Number(bet?.id_bet) - 1) {
                if (res.data?.data[0]?.status_bet === "Win") {
                    setWIN(res.data?.data[0]);
                } else if (res.data?.data[0]?.status_bet === "Lose") {
                    setLOSE(res.data?.data[0]);
                } else if (res.data?.data[0]?.status_bet === "Pending") {
                    showKQ();
                    return;
                }
                setHistoryGame(res.data.data);
                getProfile();
            }
            setKQ(res.data.data[0]);
        });*/
        axios.get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "CatDog", limit: "10" } }).then((res) => {
            if (res.data?.data.length == 0) {
                setWIN(null);
                setLOSE(null);
                return;
            }
            const data = res.data.data;
            if (!data[0]?.id_bet) {
                return setKQ(data[0]);
            }
            if (Number(data[0]?.id_bet?.id_bet) === Number(bet?.id_bet) - 1 && formatDate2(new Date(data[0]?.id_bet?.createdAt)) == formatDate2(new Date(bet?.createdAt))) {
                const win = data.filter((item) => item?.status_bet === "Win" && Number(item?.id_bet?.id_bet) === Number(bet?.id_bet) - 1);
                const lose = data.filter((item) => item?.status_bet === "Lose" && Number(item?.id_bet?.id_bet) === Number(bet?.id_bet) - 1);
                const pending = data.filter((item) => item?.status_bet === "Pending" && Number(item?.id_bet?.id_bet) === Number(bet?.id_bet) - 1);
                const total = data.reduce((accumulator, item) => {
                    return Number(item?.id_bet?.id_bet) === Number(bet?.id_bet) - 1 ? accumulator + item.money : accumulator;
                }, 0);
                if (win.length > 0) {
                    setWIN({
                        moneythang: win.reduce((accumulator, currentValue) => accumulator + currentValue.moneythang, 0),
                        money: total
                    });
                } else if (lose.length > 0) {
                    setLOSE({ money: total });
                } else if (pending.length > 0) {
                    showKQ();
                    return;
                }
                setHistoryGame(data);
                getProfile();
            }
            setKQ(data[0]);
        });
    }
    useEffect(() => {
        setNewMoney(null);
        setActiveOption(null);
        const timer = setTimeout(() => {
            showKQ();
        }, 1500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);

        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 60 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 60 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            swalInst = swal({
                title: "Thông báo hệ thống",
                text: ` Chúc mừng quý khách đã may mắn được nhận ${data?.money.toLocaleString("vi-VN")} vào tài khoản`,
                icon: "info",
                buttons: {
                    submit: "Tôi đã hiểu"
                }
            });
            const result = await swalInst;

            switch (result) {
                case "submit":
                    axios.post(`${process.env.REACT_APP_API_URL}/notification/seen`, {
                        id: data.id
                    });
                    break;
                default:
            }

            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);

    const [isOpen, setIsOpen] = useState(false);
    const openPopup = () => {
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    const [isOpen1, setIsOpen1] = useState(false);
    const openPopup1 = () => {
        setIsOpen1(true);
    };
    const closePopup1 = () => {
        setIsOpen1(false);
    };
    const getAllChoose = () => {
        return Object.entries(counters)
            .filter(([key, value]) => value > 0)
            .map(([key]) => Number(key.replace(/^s/, "")));
    };
    const calculateTotal = () => {
        const values = Object.values(counters);
        const total = values.reduce((acc, curr) => acc + curr, 0);
        return total * 1000;
    };
    function onChoose(num) {
        if (historyGame == null) return false;
        if (setting?.catdog?.open != 1) {
            return swal("Thất bại", "Trò chơi đang bảo trì", "error");
        }
        if (minute === 0 && second < 10) {
            return swal("Thất bại", "Vui lòng đợi phiên sau", "error");
        }
        if (newMoney == 0 || newMoney == null) {
            return swal("Thất bại", "Bạn chưa chọn tiền", "error");
        }
        if (counters.s1 + thongke(1) > 0 && counters.s2 + thongke(2) > 0 && num == "3") {
            return swal("Chú ý", "Chỉ được đặt tối đa 2 cửa", "warning");
        }
        if (counters.s2 + thongke(2) > 0 && counters.s3 + thongke(3) > 0 && num == "1") {
            return swal("Chú ý", "Chỉ được đặt tối đa 2 cửa", "warning");
        }
        if (counters.s1 + thongke(1) > 0 && counters.s3 + thongke(3) > 0 && num == "2") {
            return swal("Chú ý", "Chỉ được đặt tối đa 2 cửa", "warning");
        }
        if (newMoney * 1000 > profile?.money || profile?.money == 0) {
            /*if (calculateTotal() + newMoney * 1000 > profile?.money || profile?.money == 0) {
            swal("Thất bại", "Số dư không đủ", "error");
            return;
        }*/
            return swal("Thất bại", "Số dư không đủ", "error");
        }
        setActive(num.toString());
        inCrease("s" + Number(num).toString(), newMoney);
    }
    const onSubmit = (e) => {
        if (newMoney < 1 || getAllChoose().length == 0) {
            return swal("Thông báo", "Vui lòng chọn số tiền và chọn ô cược", "error");
        }
        if (minute === 0 && second < 10) {
            return swal("Thất bại", "Vui lòng đợi phiên sau", "error");
        }
        if (isSubmitting) {
            return swal("Thông báo", "Bạn thao tác quá nhanh", "warning");
        }
        setIsSubmitting(true);
        const formData = {
            result: getAllChoose().join(" "),
            id: bet?._id,
            money: calculateTotal(),
            detail: counters,
            lot: bet?.id_bet
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/catdog/choose`, formData)
            .then((res) => {
                setNewMoney(null);
                setActiveOption(null);
                setActive(null);
                setCounters({ s1: 0, s2: 0, s3: 0, s4: 0, s5: 0 });
                getProfile();
                getHistoryBet();
                swal("Đặt cược thành công", "", "success");
                setIsSubmitting(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message !== "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra, vui lòng tải lại trang", "error");
                setIsSubmitting(false);
            });
    };
    const inCrease = (key, money) => {
        if (money < 1) {
            return swal("Thông báo", "Số tiền cược không hợp lệ", "error");
        }
        setCounters((prevCounters) => {
            const newCounters = {
                ...prevCounters,
                [key]: prevCounters[key] + Number(money)
            };
            const allChoose = Object.entries(newCounters)
                .filter(([key, value]) => value > 0)
                .map(([key]) => Number(key.replace(/^s/, "")));
            const values = Object.values(newCounters);
            const total = values.reduce((acc, curr) => acc + curr, 0);
            const formData = {
                user: profile?._id,
                result: allChoose.join(" "),
                id: bet,
                money: total * 1000,
                detail: newCounters,
                newmoney: newMoney * 1000
            };
            //sendJsonMessage({ type: "choose_catdog", data: formData });
            return newCounters;
        });
    };

    useEffect(() => {
        if (historyGame != null) {
            setCounters2(historyGame.filter((item) => item.lot === bet?.id_bet && formatDate(new Date(item?.createdAt)) == formatDate(new Date(bet?.createdAt))));
        }
    }, [historyGame]);

    useEffect(() => {
        let timeoutId;
        if (KQ) {
            timeoutId = setTimeout(() => {
                setKQ(null);
                setWIN(null);
                setLOSE(null);
                setShowConfetti(false);
            }, 9500);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [KQ]);

    const [countdown, setCountdown] = useState(false);
    const runEffect = () => {
        setCountdown(true);
        setKQ(null);
        setWIN(null);
        setLOSE(null);
        setTimeout(function () {
            setCountdown(false);
            setCounters({ s1: 0, s2: 0, s3: 0, s4: 0, s5: 0 });
            setCounters2([]);
        }, 5000);
    };
    useEffect(() => {
        if (minute === 0 && second === 5) {
            runEffect();
            sendJsonMessage({ type: "roll_catdog", data: { user: profile?._id } });
            //calculateTotal() > 0 && onSubmit();
        }
    }, [minute, second]);
    const increaseNumber = () => {
        setNewMoney((prevNumber) => prevNumber + 10);
    };
    const decreaseNumber = () => {
        if (newMoney > 10) setNewMoney((prevNumber) => prevNumber - 10);
    };
    const onHandle = (type) => {
        //console.log(`on(${type})`);
    };
    const handleOptionClick = (option) => {
        setActiveOption(option);
        if (option == "max") {
            setNewMoney(Number(Math.floor(profile?.money / 1000)));
        } else {
            setNewMoney(Number(option));
        }
    };
    /*useEffect(() => {
        if (profile) {
            if (readyState !== ReadyState.OPEN) {
                const timer = setTimeout(() => {
                    window.location.reload();
                }, 1000);

                return () => clearTimeout(timer);
            }
        }
    }, [readyState, profile]);*/
    function thongke(num) {
        if (historyGame && counters2.length > 0) {
            const countTable = {};
            for (let i = 1; i <= 3; i++) {
                countTable[i] = { count: 0, totalMoney: 0 };
            }
            counters2.forEach((item) => {
                const detail = item.detail;
                const states = item.state.split(" ").map(Number);
                const length = states.length;

                states.forEach((state) => {
                    if (state >= 1 && state <= 3) {
                        countTable[state].count++;
                        countTable[state].totalMoney += detail["s" + state.toString()];
                    }
                });
            });
            return Number(countTable[num].totalMoney);
        } else {
            return 0;
        }
    }
    const [activeTab, setActiveTab] = useState("tab1");
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        getHistoryBet();
    };
    const reset = () => {
        setNewMoney(null);
        setActiveOption(null);
        setActive(null);
        setCounters({ s1: 0, s2: 0, s3: 0, s4: 0, s5: 0 });
    };
    return (
        <>
            <div className="main">
                <Header profile={profile} />
                <div className="catdog-bg">
                    <div className="catdog-bg-top">
                        <div className="catdog-header">
                            <div className="catdog-button">
                                <div className="catdog-button-item" onClick={() => navigate("/")}>
                                    <img src={require("./images/ic_home.8da6d929.png")} />
                                </div>
                                <div className="catdog-button-item" onClick={openPopup1}>
                                    <img src={require("./images/ic_history.a5ae36ca.png")} />
                                </div>
                                <div className="catdog-button-item" onClick={openPopup}>
                                    <img src={require("./images/ic_tips.8ccc7e91.png")} />
                                </div>
                            </div>
                            <div className="catdog-money">
                                <img src={require("./images/ig_diamond2.6cecddfa.png")} width={25} height={20} />
                                <div>{Number(Math.floor(profile?.money)).toLocaleString("vi-VN")}</div>
                                <img src={require("./images/ic_add_max.26512c33.png")} width={25} height={20} onClick={() => navigate("/recharge")} />
                            </div>
                        </div>
                        <div className="catdog-logo">Phiên {bet ? bet?.id_bet : "-----"}</div>
                        <div className="catdog-animation">
                            {second >= 0 && second <= 55 ? (
                                <Svga src={waiting} option={{ loop: false }} on={onHandle} />
                            ) : (
                                <Svga src={battle} option={{ loop: false }} on={onHandle} />
                            )}
                        </div>
                        {countdown == true && (
                            <div className="catdog-countdown">
                                <Svga src={time} option={{ loop: false }} on={onHandle} />
                            </div>
                        )}
                    </div>
                    <div className="catdog-bg-text">
                        {second > 5 && second <= 55 && (
                            <>
                                Thời gian còn lại &nbsp;
                                <b>
                                    {minute
                                        .toString()
                                        .split("")
                                        .map((item, index) => (
                                            <span key={index}>{item}</span>
                                        ))}
                                    <span>:</span>
                                    {second < 10 ? <span>0</span> : ""}
                                    {second
                                        .toString()
                                        .split("")
                                        .map((item, index) => (
                                            <span key={index}>{item}</span>
                                        ))}
                                </b>
                            </>
                        )}
                        {second > 0 && second <= 5 && <span>Trận đấu sắp bắt đầu</span>}
                        {(second <= 0 || second > 55) && <span>Trận đấu bắt đầu</span>}
                    </div>
                    <div className="catdog-choose">
                        <div className={`catdog-choose-cat ${counters.s1 > 0 ? "active" : ""}`} onClick={(e) => onChoose("1")}>
                            <div className="catdog-choose-rate">
                                Mèo <span>x{setting?.catdog?.thuong1 || 1}</span>
                            </div>
                            {/*<div className="catdog-total">
                                Tổng đặt{" "}
                                <span>
                                    <img src={require("./images/i1.png")} width={20} height={20} />
                                    {thongke(1)}
                                </span>
                            </div>*/}
                            <div className="catdog-choose-you">
                                Bạn đã đặt
                                <span>
                                    <img src={require("./images/i2.png")} width={20} height={20} />
                                    {(counters.s1 + thongke(1)).toLocaleString("vi-VN")}
                                </span>
                            </div>
                        </div>
                        <div className={`catdog-choose-tie ${counters.s2 > 0 ? "active" : ""}`} onClick={(e) => onChoose("2")}>
                            <div className="catdog-choose-rate">
                                Hòa <span>x{setting?.catdog?.thuong2 || 1}</span>
                            </div>
                            {/*<div className="catdog-total">
                                Tổng đặt{" "}
                                <span>
                                    <img src={require("./images/i1.png")} width={20} height={20} />
                                    {thongke(2)}
                                </span>
                            </div>*/}
                            <div className="catdog-choose-you">
                                Bạn đã đặt
                                <span>
                                    <img src={require("./images/i2.png")} width={20} height={20} />
                                    {(counters.s2 + thongke(2)).toLocaleString("vi-VN")}
                                </span>
                            </div>
                        </div>
                        <div className={`catdog-choose-dog ${counters.s3 > 0 ? "active" : ""}`} onClick={(e) => onChoose("3")}>
                            <div className="catdog-choose-rate">
                                Chó <span>x{setting?.catdog?.thuong3 || 1}</span>
                            </div>
                            {/*<div className="catdog-total">
                                Tổng đặt{" "}
                                <span>
                                    <img src={require("./images/i1.png")} width={20} height={20} />
                                    {thongke(3)}
                                </span>
                            </div>*/}
                            <div className="catdog-choose-you">
                                Bạn đã đặt
                                <span>
                                    <img src={require("./images/i2.png")} width={20} height={20} />
                                    {(counters.s3 + thongke(3)).toLocaleString("vi-VN")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="catdog-bottom">
                        {/*<div className="catdog-charge">
                            <img src={require("./images/change_diamond.c23674ae.png")} width={40} height={40} />
                            <div className="catdog-form">
                                <img src={require("./images/ic_dis.20b3e1c1.png")} width={20} height={20} onClick={decreaseNumber} />
                                <input defaultValue={newMoney} value={newMoney} onChange={(e) => setNewMoney(e.target.value)} type="number" min="1" />
                                <img src={require("./images/ic_add.be30e0d3.png")} width={20} height={20} onClick={increaseNumber} />
                            </div>
                        </div>*/}
                        <div className={`catdog-btnmax ${activeOption == "1" ? "active" : ""}`} onClick={() => handleOptionClick("1")}>
                            <button>1</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "3" ? "active" : ""}`} onClick={() => handleOptionClick("3")}>
                            <button>3</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "5" ? "active" : ""}`} onClick={() => handleOptionClick("5")}>
                            <button>5</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "10" ? "active" : ""}`} onClick={() => handleOptionClick("10")}>
                            <button>10</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "30" ? "active" : ""}`} onClick={() => handleOptionClick("30")}>
                            <button>30</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "50" ? "active" : ""}`} onClick={() => handleOptionClick("50")}>
                            <button>50</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "100" ? "active" : ""}`} onClick={() => handleOptionClick("100")}>
                            <button>100</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "200" ? "active" : ""}`} onClick={() => handleOptionClick("200")}>
                            <button>200</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "500" ? "active" : ""}`} onClick={() => handleOptionClick("500")}>
                            <button>500</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "1000" ? "active" : ""}`} onClick={() => handleOptionClick("1000")}>
                            <button>1000</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "2000" ? "active" : ""}`} onClick={() => handleOptionClick("2000")}>
                            <button>2000</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "5000" ? "active" : ""}`} onClick={() => handleOptionClick("5000")}>
                            <button>5000</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "10000" ? "active" : ""}`} onClick={() => handleOptionClick("10000")}>
                            <button>10k</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "20000" ? "active" : ""}`} onClick={() => handleOptionClick("20000")}>
                            <button>20k</button>
                        </div>
                        <div className={`catdog-btnmax ${activeOption == "max" ? "active" : ""}`} onClick={() => handleOptionClick("max")}>
                            <button>MAX</button>
                        </div>
                        <div className="catdog-reset" onClick={reset}>
                            Reset
                        </div>
                        <div className="catdog-submit" onClick={onSubmit}>
                            Đặt cược
                        </div>
                    </div>
                    <div className="bangxephang">
                        <div>
                            <img src={require("../Greedy/images/rank_1.44d42cc.png")} />
                        </div>
                        <div>
                            <h3>BXH THU NHẬP HÔM NAY</h3>
                            <h4>
                                <img src={require("../Greedy/images/1diamond.93d10cf.png")} width="15" style={{ marginRight: "5px" }} />
                                {bxh && bxh[0]?.success?.toLocaleString("vi-VN")}
                            </h4>
                        </div>
                        <div>
                            <button onClick={() => setViewBXH(true)} style={{ background: "#fee2a1", color: "#453407" }}>
                                XEM TẤT CẢ
                            </button>
                        </div>
                    </div>
                </div>

                {viewBXH == true && (
                    <div className="popup-backdrop">
                        <div className="popup-bxh" style={{ background: "#ffedc3" }}>
                            <div className="popup-bxh-title">BXH Thu Nhập Hôm Nay</div>
                            <div className="popup-bxh-content">
                                {bxh.length > 0
                                    ? bxh.map((item, index) => (
                                          <div className="popup-bxh-detail">
                                              <div>
                                                  {index == 0 ? (
                                                      <img src={require("../Greedy/images/rank_1.44d42cc.png")} width="30" style={{ marginRight: "10px" }} />
                                                  ) : index == 1 ? (
                                                      <img src={require("../Greedy/images/rank_2.4019bf3.png")} width="30" style={{ marginRight: "10px" }} />
                                                  ) : index == 2 ? (
                                                      <img src={require("../Greedy/images/rank_3.f72353c.png")} width="30" style={{ marginRight: "10px" }} />
                                                  ) : (
                                                      <span>{index + 1}</span>
                                                  )}
                                              </div>
                                              <div>
                                                  <h5>{item?.username}</h5>
                                              </div>
                                              <div>
                                                  <img src={require("../Greedy/images/1diamond.93d10cf.png")} width="20" />
                                                  <strong>{item?.success?.toLocaleString("vi-VN")}</strong>
                                              </div>
                                          </div>
                                      ))
                                    : null}
                            </div>
                            <div onClick={() => setViewBXH(false)} className="popup-bxh-close">
                                &times;
                            </div>
                        </div>
                    </div>
                )}

                {isOpen && (
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header" style={{ background: "#94540d" }}>
                                Hướng dẫn cách chơi
                            </div>
                            <div className="popup-content">
                                1. Bạn có 50s để dự đoán kết quả trận đấu.
                                <div className="br" />
                                2. Trận đấu sẽ bắt đầu sau 50s đếm ngược, bạn sẽ nhận gấp 3 lần tiền thưởng nếu bạn đoán đúng kết quả.
                                <div className="br" />
                                3. Bạn có thể dự đoán Mèo thắng / Hoà / Chó thắng (Chỉ được đặt tối đa 2 kết quả)
                                <div className="br" />
                                4. Nổ hũ chó - Nổ hũ mèo bạn sẽ nhận được phần thưởng thắng lớn gấp 6 đến 15 lần so với giá trị đặt cược ban đầu nếu dự đoán chính xác.
                                <div className="br" />
                                5. Cược tối thiểu 1 điểm = 1.000đ
                                <div className="br" />
                                6. Trò chơi này được cung cấp bởi BIGO LIFE COMPANY
                            </div>
                            <button onClick={closePopup} className="popup-close" style={{ background: "#94540d", boxShadow: "none", textShadow: "none" }}>
                                Đóng
                            </button>
                        </div>
                    </div>
                )}

                {isShow === true && ls.status_bet !== "Pending" ? (
                    <>
                        <div className="modal" style={{ zIndex: "9999999" }}>
                            <div className="modaloverlay"></div>
                            <div className="modalbody">
                                <div>
                                    <div className="modalinner" style={{ padding: "10px 15px" }}>
                                        <div className="modalheader" style={{ padding: "10px 0 20px" }}>
                                            Chi tiết cược
                                        </div>

                                        {ls?.id_bet?.id_bet ? (
                                            <>
                                                <div className="lsgd-table">
                                                    <div>Trò chơi</div>
                                                    <div>Cat And Dog</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Phiên</div>
                                                    <div>{ls?.id_bet?.id_bet}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Thời gian</div>
                                                    <div>{formatDate(new Date(ls.createdAt))}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Đặt cược</div>
                                                    <div>
                                                        {Object.entries(ls.detail).map(
                                                            ([key, value]) =>
                                                                value > 0 && (
                                                                    <div key={key}>
                                                                        {GetNameChoose(key.slice(-1), null, "CatDog").replace("Chọn ", "")}:{" "}
                                                                        {(value * 1000).toLocaleString("vi-VN")} đ
                                                                    </div>
                                                                )
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng Cược</div>
                                                    <div>{Number(ls?.money).toLocaleString("vi-VN")} </div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng thắng</div>
                                                    <div>{Number(ls?.moneythang).toLocaleString("vi-VN")} </div>
                                                </div>
                                                <h3
                                                    style={{
                                                        fontSize: "0.4rem",
                                                        margin: "20px 0 10px"
                                                    }}>
                                                    Kết quả phiên {ls?.id_bet?.id_bet}
                                                </h3>
                                                <div
                                                    className="history_xucsac"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        fontSize: ".7rem",
                                                        color: "#000"
                                                    }}>
                                                    {GetNameChoose(ls.id_bet.result, null, "CatDog").replace("Chọn ", "")}
                                                </div>
                                            </>
                                        ) : null}
                                        <div>
                                            <div className="modalformcontrols">
                                                <button
                                                    onClick={() => setShow(false)}
                                                    className="popup-close"
                                                    style={{
                                                        background: "#94540d",
                                                        boxShadow: "none",
                                                        textShadow: "none"
                                                    }}>
                                                    ĐÓNG
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}

                {KQ && total && second < 59 && second > 50 && (
                    <div className="popup-backdrop">
                        <div className="catdog-bangketqua">
                            <div className="catdog-ketqua">
                                <div className="catdog-ketqua-content">
                                    <div className="catdog-award">
                                        {total && total[0]?.result == "1" && <Svga src={cat} option={{ loop: false }} on={onHandle} />}
                                        {total && total[0]?.result == "2" && <Svga src={draw} option={{ loop: false }} on={onHandle} />}
                                        {total && total[0]?.result == "3" && <Svga src={dog} option={{ loop: false }} on={onHandle} />}
                                        {total && total[0]?.result == "4" && (
                                            <div className="nohu">
                                                <Svga src={cat} option={{ loop: false }} on={onHandle} />
                                            </div>
                                        )}
                                        {total && total[0]?.result == "5" && (
                                            <div className="nohu">
                                                <Svga src={dog} option={{ loop: false }} on={onHandle} />
                                            </div>
                                        )}
                                    </div>
                                    {showConfetti && <div style={{ marginTop: "15px", fontSize: "1rem" }}>NỔ HŨ</div>}
                                    <div>
                                        {WIN != null && (
                                            <>
                                                <div style={{ marginTop: "15px" }}>
                                                    Bạn đã thắng phiên này.
                                                    <br />
                                                    <div style={{ fontSize: "0.8rem" }}>
                                                        <img
                                                            alt=""
                                                            src={require(`./images/icon-diamond.090bdef2.png`)}
                                                            style={{ height: "40px", margin: "0 5px", position: "relative", top: "10px" }}
                                                        />
                                                        {WIN?.moneythang.toLocaleString("vi-VN")}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {LOSE != null && (
                                            <>
                                                {showConfetti && <div style={{ marginTop: "15px", fontSize: "1rem" }}>NỔ HŨ</div>}
                                                <div style={{ marginTop: "15px" }}>Bạn đã thua phiên này.</div>
                                            </>
                                        )}
                                        {WIN == null && LOSE == null && <div style={{ marginTop: "15px" }}>Bạn chưa tham gia phiên này.</div>}
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setKQ(null);
                                        setWIN(null);
                                        setLOSE(null);
                                        setShowConfetti(false);
                                    }}
                                    className="catdog-bangketqua-dong">
                                    OK
                                </div>
                                <div
                                    onClick={() => {
                                        setKQ(null);
                                        setWIN(null);
                                        setLOSE(null);
                                        setShowConfetti(false);
                                    }}
                                    className="catdog-bangketqua-close">
                                    &times;
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isOpen1 && (
                    <div className="popup-backdrop">
                        <div className="popup-main" style={{ background: "#ffedc3" }}>
                            <div className="popup-header" style={{ background: "transparent", color: "#94540d" }}>
                                Lịch Sử
                            </div>
                            <ul className="tab-navigation tab-history-catdog">
                                <li className={activeTab === "tab1" ? "active" : ""} onClick={() => handleTabClick("tab1")}>
                                    Lịch sử trò chơi
                                </li>
                                <li className={activeTab === "tab2" ? "active" : ""} onClick={() => handleTabClick("tab2")}>
                                    Lịch sử của tôi
                                </li>
                            </ul>
                            <div className="popup-content">
                                {activeTab === "tab1" && (
                                    <>
                                        {total != null && (
                                            <div className="catdog-table-scroll">
                                                <table className="catdog-table">
                                                    <thead>
                                                        <tr>
                                                            <td>Phiên</td>
                                                            <td>Mèo</td>
                                                            <td>Hòa</td>
                                                            <td>Chó</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {total.map((item, key) => (
                                                            <tr key={key}>
                                                                <td>{item.id_bet}</td>
                                                                <td>{(item.result == 1 || item.result == 4) && <div className="tick"></div>}</td>
                                                                <td>{item.result == 2 && <div className="tick"></div>}</td>
                                                                <td>{(item.result == 3 || item.result == 5) && <div className="tick"></div>}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </>
                                )}
                                {activeTab === "tab2" && (
                                    <>
                                        {historyGame != null ? (
                                            <div className="content-history award_tb" style={{ color: "#555" }}>
                                                {historyGame?.map((item, key) => (
                                                    <>
                                                        {item.sanh === "CatDog" ? (
                                                            <div
                                                                style={{ borderColor: "#bbadad" }}
                                                                key={key}
                                                                className="item_inner"
                                                                onClick={() => {
                                                                    setLs(item);
                                                                    setShow(true);
                                                                }}>
                                                                <div className="item_history">
                                                                    <div className="title_item_history">
                                                                        <span className="sanh">{item.sanh}</span>
                                                                        <span
                                                                            className={`type_state ${
                                                                                item?.status_bet === "Pending" ? "pending" : item?.status_bet === "Win" ? "win" : "lose"
                                                                            }`}>
                                                                            {item?.status_bet}
                                                                        </span>
                                                                    </div>
                                                                    <div className="id_history_sanh">Phiên cược: {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                                                    <div className="id_history_sanh">{GetNameChoose(item.state, item.type, item.sanh)}</div>
                                                                </div>
                                                                <div className="money_history">
                                                                    <span className="money" style={{ color: "#9356f7", fontWeight: "bold" }}>
                                                                        {Number(item?.money).toLocaleString("vi-VN")}
                                                                    </span>
                                                                    <div className="time_choose">{formatDate(new Date(item?.createdAt))}</div>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </>
                                                ))}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </>
                                )}
                            </div>
                            <button
                                onClick={closePopup1}
                                className="popup-close"
                                style={{
                                    background: "#94540d",
                                    boxShadow: "none",
                                    textShadow: "none"
                                }}>
                                Đóng
                            </button>
                        </div>
                    </div>
                )}

                {showConfetti && (
                    <Confetti
                        style={{
                            width: "100vw",
                            height: "100vh",
                            maxWidth: "540px",
                            zIndex: "999999"
                        }}
                    />
                )}
            </div>
        </>
    );
}
export default CatDog;
