import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function WithDraw() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [bank, setBank] = useState(null);
    const [newMoney, setNewMoney] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [load, setLoad] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile !== res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => sessionStorage.removeItem("user"));
        axios
            .get(`${process.env.REACT_APP_API_URL}/bank/getBank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(null));
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting !== res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);
    const onSubmit = (data) => {
        if (load == true) return false;
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) > profile.money) {
            setError("money", {
                type: "minLength",
                message: "Số dư không đủ"
            });
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) <= 0 || typeof Number(data.money.replaceAll(".", "").replaceAll(",", "")) !== "number") {
            setError("money", {
                type: "minLength",
                message: "Vui lòng nhập số tiền hợp lệ"
            });
            return false;
        }
        if (!data.paypwd) {
            setError("paypwd", {
                type: "minLength",
                message: "Vui lòng nhập mật khẩu rút tiền"
            });
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) < setting?.minrut) {
            swal("Chú ý", `Số tiền rút tối thiểu là ${(setting?.minrut).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) > setting?.maxrut) {
            swal("Chú ý", `Số tiền rút tối đa là ${(setting?.maxrut).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (data.detail) {
            const formData = {
                money: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
                type_payment: "RÚT",
                detail: data.detail,
                status_payment: "Pending",
                user: profile._id,
                paypwd: data.paypwd ? data.paypwd : ""
            };
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
                .then((res) => {
                    swal({
                        title: "Thông báo",
                        text: "Tạo yêu cầu rút tiền thành công!",
                        icon: "success",
                        buttons: "OK"
                    }).then(() => navigate("/profile"));
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
                    setLoad(false);
                });
        } else {
            const formData = {
                money: data.money,
                type_payment: "RÚT",
                detail: data.stk + " - " + data.bank + " - " + data.name,
                status_payment: "Pending",
                user: profile._id,
                paypwd: data.paypwd ? data.paypwd : ""
            };
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
                .then((res) => {
                    swal({
                        title: "Thông báo",
                        text: "Tạo yêu cầu rút tiền thành công!",
                        icon: "success",
                        buttons: "OK"
                    }).then(() => navigate("/profile"));
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
                    setLoad(false);
                });
        }
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Rút tiền</h1>
            </div>
            <div className="main">
                <AnimatedPage>
                    <div className="content_profile" style={{ margin: "0.5rem 0 0" }}>
                        {/*<Link to="/addbank" style={{ display: "block", margin: "1.8rem 0 0" }}>
                        <div className="box-banking box-banking2">
                            <div className="money_banking">
                                <h3>Số dư khả dụng</h3>
                                {profile ? (
                                    <>
                                        <h4>{Math.floor(profile.money).toLocaleString('vi-VN')}₫</h4>
                                    </>
                                ) : (
                                    <>
                                        <h4>0₫</h4>
                                    </>
                                )}
                            </div>
                            <div className="ctk">{profile ? profile.username : null}</div>
                            <div className="icon_credit">
                                <img alt="" src={require("../../img/icon_credit.png")} />
                            </div>
                        </div>
                    </Link>*/}
                        <form className="form-lg form-box" onSubmit={handleSubmit(onSubmit)} style={{ margin: "0" }}>
                            <div>
                                {bank == null ? (
                                    <>
                                        <div>
                                            <p>Vui lòng thêm ngân hàng</p>
                                        </div>
                                        <Link to="/addbank" className="btn-medium">
                                            Thêm Ngân Hàng
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <div className="input">
                                            <label>Nhập số tiền cần rút</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value={newMoney}
                                                {...register("money", {
                                                    required: true
                                                })}
                                                onClick={() => setNewMoney(null)}
                                                onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString('vi-VN'))}
                                            />
                                        </div>
                                        {errors.money ? <p style={{ color: "red" }}>{errors.money.message}</p> : null}
                                        <div className="input">
                                            <label>Nhập mật khẩu rút tiền</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                {...register("paypwd", {
                                                    required: true
                                                })}
                                            />
                                        </div>
                                        {errors.paypwd ? <p style={{ color: "red" }}>{errors.paypwd.message}</p> : null}
                                        <label>Chọn thông tin thanh toán</label>
                                        <select
                                            style={{ color: "#333" }}
                                            {...register("detail", {
                                                required: true
                                            })}>
                                            <option value="">Chọn ngân hàng</option>
                                            {bank.map((item, index) => (
                                                <option key={index} value={item.name_bank + " - " + item.fullname + " - " + item.stk}>
                                                    {"STK " + item.stk + " - CTK " + item.fullname + " [ " + item.name_bank + " ]"}
                                                </option>
                                            ))}
                                        </select>
                                        <button type="submit" className="btn-submit">
                                            Xác nhận
                                        </button>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </AnimatedPage>
            </div>
            <Footer />
        </>
    );
}
export default WithDraw;
