import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function EditBank() {
    axios.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const { id } = useParams();
    const { register, handleSubmit, setError } = useForm();
    const [bank, setBank] = useState(null);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bank/user/${id}`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => sessionStorage.removeItem("user"));
    }, []);
    const onSubmit = (data) => {
        const formData = {
            id: id,
            name_bank: data.name_bank,
            stk: data.stk,
            fullname: data.fullname
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/bank/updateBank`, formData)
            .then((res) => {
                swal("Thành công", "Sửa ngân hàng thành công", "success");
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Sửa thông tin</h1>
            </div>
            <div className="main">
                <div className="content_profile" style={{ margin: "0.3rem 0 0" }}>
                    {bank != null ? (
                        <AnimatedPage>
                            <form className="form-lg form-box" onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <div className="input">
                                        <label>Tên ngân hàng</label>
                                        <input
                                            className="ipadd"
                                            type="text"
                                            {...register("name_bank", {
                                                required: true
                                            })}
                                            placeholder="Tên ngân hàng"
                                            defaultValue={bank.name_bank}
                                        />
                                    </div>
                                    <div className="input">
                                        <label>Số tài khoản</label>
                                        <input
                                            className="ipadd"
                                            type="number"
                                            {...register("stk", {
                                                required: true
                                            })}
                                            placeholder="Số tài khoản"
                                            defaultValue={bank.stk}
                                        />
                                    </div>
                                    <div className="input">
                                        <label>Chủ tài khoản</label>
                                        <input
                                            className="ipadd"
                                            type="text"
                                            {...register("fullname", {
                                                required: true
                                            })}
                                            placeholder="Chủ tài khoản"
                                            defaultValue={bank.fullname}
                                        />
                                    </div>
                                    <button type="submit" className="btn-submit">
                                        Xác nhận
                                    </button>
                                </div>
                            </form>
                        </AnimatedPage>
                    ) : (
                        <div>...</div>
                    )}
                </div>

                <Footer />
            </div>
        </>
    );
}
export default EditBank;
